<template>
  <div>
    <v-simple-table
      fixed-header
      :height="tableHeight"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" v-if="!idPos">
              <SelectFilter
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                :options="posOptions"
                name="id_pos"
                sort-name="pos_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.day_off')"
                :placeholder="$t('labels.day_off')"
                name="date"
                sort-name="date"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.note')"
                :placeholder="$t('labels.note')"
                name="note"
                sort-name="note"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 120px">
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                :options="statusOptions"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <v-btn color="primary" small @click="showCreateDialog">
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <td v-if="!idPos">
              <v-autocomplete
                v-model.number="item.id_pos"
                :items="posOptions"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <DatePicker
                :min-date="currentDate"
                :default-value="item.date"
                :id-item="item.id"
                :disabled="!item.editing"
                name="date"
                @onChange="onItemChange"
              />
            </td>
            <td>
              <v-text-field
                v-model="item.note"
                type="text"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="py-1">
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateConfig(item)"
                  >{{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>

    <v-dialog v-model="createDialog" max-width="400px" persistent>
      <DayOffCreate
        v-if="createDialog"
        @cancel="hideCreateDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { STATUS_OPTIONS } from "@/libs/const";
import moment from "moment";

export default {
  name: "WorkingTimeList",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    DatePicker: () => import("@/components/common/DatePicker"),
    DayOffCreate: () => import("@/components/pos/DayOffCreate"),
  },
  props: {
    idPos: {
      type: [String, Number],
      default: () => null,
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    posOptions: [],
    statusOptions: [...STATUS_OPTIONS],
    items: [],
    filters: {},
    isLoading: false,
    createDialog: false,
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    tableHeight() {
      return this.idPos ? "calc(100vh - 265px)" : "calc(100vh - 205px)";
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    if (this.idPos) {
      this.filters = { ...this.filters, id_pos: this.idPos };
    }
  },
  mounted() {
    this.getPos();
    this.getList();
  },
  methods: {
    showCreateDialog() {
      this.createDialog = true;
    },
    hideCreateDialog() {
      this.createDialog = false;
    },
    async getPos() {
      if (this.idPos) {
        return false;
      }
      const { data } = await httpClient.post("/pos-all-active");
      this.posOptions = data.map((e) => ({
        value: e.id,
        text: e.code,
      }));
    },
    cancel() {
      this.$emit("cancel");
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_pos: this.idPos || null,
        date: null,
        note: null,
        status: 1,
        editing: true,
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-save-day-off", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/pos-day-off-list", { ...this.filters })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((item) => ({
            ...item,
            date: moment(item.date).format("YYYY-MM-DD"),
            editing: false,
          }));
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    onItemChange(val) {
      const items = [...this.items];
      let item = [...items].find((i) => i.id === val.id);
      if (item) {
        const itemIndex = [...items].findIndex((i) => i.id === val.id);
        item = { ...item, [val.name]: val.value };
        items[itemIndex] = item;
        this.items = [...items];
      }
    },
  },
};
</script>

<style scoped></style>
